*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif;
}

:root {
  --colore-background-big: rgba(0, 114, 206, 1);
  --color-input-range: #fef400;
  --color-icon-big: rgb(254, 244, 0);
  --color-icon-navbar-evo: #dad3d3;
  --color-player-evo-volume: linear-gradient(
    90deg,
    rgba(255, 78, 0, 1) 0%,
    rgba(236, 159, 5, 1) 100%
  );
  --player-small-volume-icon: #575757;
  --player-small-play-pause: #EC008D;
  --player-small-volume: linear-gradient(
    90deg,
    rgba(1, 129, 209, 0.301) 0%,
    rgba(252, 243, 90, 0.301) 100%
  );
}
.container-radiosa {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

/* Big player CSS*/

.bgimageblur {
  bottom: 0;
  height: 270px;
  left: 0;
  position: absolute;
  top: 0px;
  right: 0;
  margin: auto;
  width: 270px;
  filter: blur(7.5px);
  z-index: 0;
}

.img-fluid-big {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50% !important;
  border: 5px solid hsla(0, 0%, 100%, 0.2) !important;
}

.bordOpaque {
  border-radius: 50%;
}
.player-big {
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  margin-top: 30px;
  display: block;
}
#player-big-mute,
#player-big-max,
#player-big-percentage {
  color: white;
  font-size: 7vw;
  cursor: default;
}

#player-big-mute:hover,
#player-big-max:hover {
  color: var(--color-icon-big);
  font-size: 7vw;
  cursor: default;
}
#player-big-play,
#player-big-pause {
  color: white;
  font-size: 19vw;
  cursor: default;
}
#player-big-play:hover,
#player-big-pause:hover {
  color: var(--color-icon-big);
  font-size: 19vw;
  cursor: default;
}
.player-small > div {
  text-align: center;
  border-radius: 25px 0px 0px 25px;
}
.player-big-title-volume {
  width: 95%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
}
#player-big-singer {
  font-weight: bolder;
  color: white;
  width: 100%;
  height: 100%;
  font-size: 22px;
  margin: 0;
  text-align: center;
  padding-top: 10px;
}
#player-big-song {
  color: white;
  width: 100%;
  font-size: 33px;
  padding: 25px;
}
.player-big-title-song {
  width: auto;
  height: auto;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.player-big-video {
  width: 100%;
  height: 70%;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: 50% 50px;
  z-index: 0;
  border-radius: 10%;
  background-color: var(--colore-background-big);
  position: relative;
}
.player-big-control-volume {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 5;
}

.player-big-mute-max {
  width: 95%;
  height: auto;
  display: flex;
  margin: 10px auto;
  flex-direction: row;
  justify-content: space-between;
}
.player-big-right-control {
  float: right;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#player-big-max {
  width: auto;
  height: auto;
}
#player-big-percentage {
  width: auto;
  padding-left: 5vw;
  font-size: 5vw;
}

.player-big-album {
  height: 250px;
  width: 250px;
  border-radius: 50%;
}

.player-big-marquee {
  width: 100%;
  height: fit-content;
  line-height: auto;
  white-space: nowrap;
  overflow: hidden;
  z-index: 5;
}

.player-big-marquee-song {
  width: 100%;
  margin: 0 auto;
  height: fit-content;
  line-height: auto;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.player-big-marquee-song p {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}
#player-big-song-returned {
  display: block;
  z-index: 5;
}
#player-big-song-radio {
  display: none;
  z-index: 5;
}

#player-big-play {
  width: auto;
  height: auto;
}
#player-big-pause {
  width: auto;
  height: auto;
  display: none;
}
#player-big-mute {
  width: auto;
  height: auto;
}
.player-big-play-mute {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  padding: 10px 0px 0px 60px;
}
#player-big-rewind {
  width: auto;
  height: auto;
  color: var(--color-icon-big);
  cursor: default;
}
#player-big-rewind {
  width: auto;
  height: auto;
  color: var(--color-icon-big);
  cursor: default;
}
.player-big-slider {
  background: linear-gradient(
    to right,
    var(--color-input-range) 0%,
    var(--color-input-range) 50%,
    white 50%,
    white 100%
  );
  border-radius: 8px;
  height: 5px;
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
  margin: 0 auto;
}
.player-big-slide-container {
  margin: 0 15px;
}
input[type="range"]::-moz-range-track {
  box-shadow: none !important;
}
input[type="range"]::-moz-range-thumb {
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 50%;
  background: var(--color-input-range);
  border: 5px solid var(--color-input-range);
}
input[type="range"]::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  background: var(--color-input-range);
  border: 5px solid var(--color-input-range);
}
input[type="range"]::-webkit-slider-runnable-track {
  box-shadow: none !important;
}
input[type="range"]::-ms-thumb {
  width: 20px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
  background: var(--color-input-range);
  border: 5px solid var(--color-input-range);
}

input[type="range"]::-ms-track {
  box-shadow: none !important;
}

.material-icons {
  color: var(--color-icon-navbar-evo);
  font-size: 45px;
}

/* Small player CSS*/

#canzone_small {
  font-weight: bolder;
  width: 80%;
  height: auto;
  font-size: 30px;
  display: flex;
  flex-direction: row;
  margin-right: 5px;
}
#cantante_small {
  font-weight: lighter;
  width: 92%;
  height: auto;
  font-size: 20px;
  display: flex;
  flex-direction: row;
}

#canzone_small p {
  margin-right: 10px;
}

#cantante_small p {
  margin-right: 8px;
}
.player-small-song-volume {
  position: relative;
  margin: 0;
  max-height: 100px;
  max-width: 525px;
  border-right: solid 2px var(--player-small-volume-icon);
  /* border-right: solid 2px var; */
  border-right-color: #EC008D;
}

.player-small-volume {
  text-align: right;
  max-height: 100px;
  display: block;
  font-size: 9pt;
  position: relative;
  z-index: 5;
  min-height: 0px;
  min-width: 5%;
  border-right: solid 1px;
  border-right-color: #EC008D;
  /*border-right: solid 1px var(--player-small-volume-icon);*/
  max-width: 525px;
  height: 100px !important;
  overflow-x: hidden;
  overflow-y: auto;
  background: linear-gradient(
    90deg,
    rgb(36 94 173 / 80%) 0%,
    rgb(225 19 140 / 70%) 100%
  );
}
#player-small-rsz {
  cursor: grabbing;
  position: absolute;
  /* right: 5px; */
  /* top: 5px; */
  z-index: 7;
  width: 525px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: grabbing;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
#player-small-rsz ::selection {
  color: none;
  background: none;
}

#player-small-volume > .material-icons {
  font-size: 30px;
  cursor: grabbing;
}
#player-small-pause {
  display: none;
}
.player-small-title-volume {
  position: relative;
  top: -100px;
  display: flex;
  flex-direction: row;
  width: 500px;
  height: inherit;
}

.player-small {
  margin: 100px auto;
  width: 700px;
  display: none;
  height: auto;
  grid-template-columns: 75px 525px 100px;
  grid-template-rows: 100px;
  border: 2px solid;
  border-color: #EC008D;
  /*border: 2px solid var(--player-small-volume-icon);*/
  border-radius: 27px 27px 27px 27px;
}
.player-small > div {
  text-align: center;
  border-radius: 25px 0px 0px 25px;
}
.radiosa-player .video-js {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
  display: none;
  margin: 0 auto;
  background-color: transparent;
}

.material-icons {
  font-size: 45px;
  cursor: default;
}
#max_small {
  width: auto;
  height: auto;
}
#player-small-percentage {
  font-size: 20px;
  justify-content: space-between;
  position: absolute;
  bottom: -36px;
  right: -22px;
  width: 50px;
  display: flex;
  height: 30px;
  color: var(--player-small-volume-icon);
  flex-direction: column-reverse;
  padding-bottom: 6px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  z-index: 10;
  color: #dad3d3;
  /* shake it*/
}

.player-small-title-song {
  width: auto;
  height: auto;
  color: var(--player-small-volume-icon);
  position: relative;
  top: 22px;
  left: 5px;
  font-size: 25px;
  padding-bottom: 5px;
  text-align: initial;
  z-index: 6;
  color: #dad3d3;
  /* shake it*/
}

.player-small-marquee {
  width: 87%;
  line-height: auto;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.player-small-marquee p {
  flex-direction: column;
  display: flex;
}
.player-small-play-pause {
  width: auto;
  height: auto;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2em;
  background-color: var(--player-small-play-pause);
  border-right: 2px solid var(--player-small-volume-icon);
}

.img-fluid-small {
  border-radius: 27px;
  width: 100px;
  height: 100px;
  object-fit: revert;
  padding-right: 4px;
}
#player-small-song-thumb {
  display: none;
  width: 100px;
  height: 100px;
}
#player-small-song-radio > .material-icons {
  font-size: 50px;
  margin: 0 auto;
}
.player-small-title-volume > #player-small-mute {
  position: relative;
  display: block;
  top: 69px;
  right: -10px;
  font-size: 30px;
  z-index: 10;
}
.player-small-title-volume > #player-small-max {
  position: relative;
  display: none;
  top: 69px;
  right: -10px;
  font-size: 30px;
  z-index: 10;
}
#player-small-song-radio {
  display: block;
  margin: auto 0;
}
#player-small-song-returned {
  display: none;
}

.vjs-error-display,
.vjs-modal-dialog {
  display: none;
}
.vjs-modal-dialog-content {
  display: none;
}
.vjs-error-display,
.vjs-modal-dialog {
  display: none;
}
span {
  color: black;
}

/* Evo player CSS */

.player-evo {
  width: 100%;
  height: auto;
  background-color: whitesmoke;
  margin: 100px auto;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 10% 30% 30% 15% 15%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.player-evo-navbar {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  background-color: var(--color-icon-navbar-evo);
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid var(--color-icon-navbar-evo);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.player-evo-name-radio {
  padding-left: 10px;
  width: 75%;
  color: #f9d976;
  margin: auto 0;
  font-size: 20px;
  height: inherit;
}
.player-evo-social > .material-icons {
  size: 30px;
}
.player-evo-img-fluid {
  display: none;
  width: 95%;
  height: 92%;
  object-fit: contain;
  margin: 10px auto;
}

.player-evo-image-container .material-icons {
  font-size: 150px;
  margin: auto 0;
}
#player-evo-name-container {
  margin-left: 10px;
  margin-bottom: 5px;
}
.player-evo-container-volume > #player-evo-mute {
  display: block;
  font-size: 30px;
  z-index: 10;
  color: var(--color-icon-navbar-evo);
}
.player-evo-container-volume > #player-evo-max {
  display: none;
  font-size: 30px;
  z-index: 10;
  color: var(--color-icon-navbar-evo);
}
.player-evo-social-container {
  width: 25%;
  height: inherit;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}
.player-evo-marquee {
  width: 90%;
  line-height: auto;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.player-evo-marquee p {
  flex-direction: column;
  display: flex;
}

@keyframes marquee {
  0% {
    transform: translate(3%, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

#player-evo-social {
  font-size: 30px;
  color: blue;
  padding-right: 5px;
}
.player-evo-social {
  display: flex;
  width: fit-content;
  height: inherit;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 20px;
  margin: auto 0;
}
.player-evo-image-container {
  background-color: whitesmoke;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 4;
  display: flex;
  justify-content: center;
  border-bottom: 3px solid gainsboro;
}
.player-evo-data-container {
  margin-right: 5px;
  background-color: whitesmoke;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 4;
  grid-row-end: 6;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-bottom: 2px solid gainsboro;
  padding-right: 4px;
}
.player-evo-interval {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  margin-left: 10px;
}

.player-evo-interval p {
  font-size: small;
}
.player-evo-name-container {
  width: 100%;
  height: auto;
  font-weight: bold;
  font-size: x-large;
  margin-left: 5px;
}

.player-evo-speaker {
  width: 100%;
  height: auto;
  font-size: large;
  margin-left: 10px;
  margin-bottom: 5px;
}

.player-evo-songs-data {
  background-color: whitesmoke;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 6;
  grid-row-end: 7;
  display: flex;
  flex-direction: row;
  border-bottom: 3px solid gainsboro;
}
#player-evo-singer {
  font-size: larger;
  font-weight: bold;
}
#player-evo-singer2 {
  font-size: larger;
  font-weight: bold;
  margin-left: 5px;
}
#player-evo-singer3 {
  font-size: larger;
  font-weight: bold;
  margin-left: 5px;
}
#player-evo-singer4 {
  font-size: larger;
  font-weight: bold;
  margin-left: 5px;
}
#player-evo-song {
  font-size: larger;
  font-weight: bold;
}
#player-evo-song2 {
  font-size: larger;
  font-weight: bold;
  margin-left: 5px;
}
#player-evo-song3 {
  font-size: larger;
  font-weight: bold;
  margin-left: 5px;
}
#player-evo-song4 {
  font-size: larger;
  font-weight: bold;
  margin-left: 5px;
}
.player-evo-songs-name {
  width: 60%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 5px;
  font-size: small;
}
.player-evo-songs-image {
  width: 40%;
  height: 74%;
  object-fit: cover;
  align-self: center;
  padding-left: 5px;
  justify-content: center;
}
#player-evo-songs-image {
  display: none;
}
#imgNotReturned_evo {
  border-radius: 50%;
  display: block;
  object-fit: revert;
}
#player-evo-notReturned {
  display: block;
}
#player-evo-notReturned .material-icons {
  font-size: 125px;
}

.plaver-evo-footer {
  background-color: whitesmoke;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 7;
  grid-row-end: 8;
  display: flex;
  flex-direction: row;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
#player-evo-play {
  width: auto;
  height: auto;
  color: var(--color-icon-navbar-evo);
  margin: 0 auto;
}
#player-evo-pause {
  width: auto;
  height: auto;
  color: var(--color-icon-navbar-evo);
  margin: 0 auto;
  display: none;
}

.player-evo-control-volume {
  position: relative;
  margin: 0;
  width: 75%;
  height: 100%;
}
.player-evo-control-volume {
  color: none;
  background: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.player-evo-play-pause {
  color: none;
  background: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.player-evo-play-pause {
  width: 25%;
  height: 100%;
  justify-content: flex-start;
  padding: 5px 5px;
  display: flex;
  flex-direction: row;
}
.player-evo-container-volume {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  position: relative;
  bottom: 30px;
}
.player-evo-container-volume .material-icons {
  font-size: 30px;
}
.player-evo-volume {
  height: 100%;
  display: flex;
  font-size: 9pt;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  place-items: flex-end;
  min-height: 0px;
  min-width: 5%;
  max-width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  background: var(--color-player-evo-volume);
  border-bottom-right-radius: 0px;
}
#player-evo-percentage {
  color: var(--color-icon-navbar-evo);
}
#player-evo-rsz {
  position: absolute;
  right: 5px;
  width: 30px;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: grabbing;
  color: var(--color-icon-navbar-evo) !important;
}

.player-evo-control-volume ::selection {
  color: none;
  background: none;
}

#player-evo-rsz > .material-icons {
  font-size: 30px;
  cursor: grabbing;
  color: var(--color-icon-navbar-evo) !important;
}

@media only screen and (min-width: 600px) {
  .container-radiosa {
    width: 85%;
    height: 90%;
    margin: 0 auto;
  }

  /* Big player CSS */
  #player-big-mute,
  #player-big-max {
    color: white;
    font-size: 35px;
    cursor: default;
  }
  #player-big-mute:hover,
  #player-big-max:hover {
    color: var(--color-icon-big);
    font-size: 35px;
    cursor: default;
  }

  #player-big-play,
  #player-big-pause {
    color: white;
    font-size: 80px;
    cursor: default;
  }
  #player-big-play:hover,
  #player-big-pause:hover {
    color: var(--color-icon-big);
    font-size: 80px;
    cursor: default;
  }

  #player-big-percentage {
    width: auto;
    padding-left: 10px;
    margin: 4px 0px;
    font-size: 25px;
  }

  .player-big-right-control {
    float: right;
    color: black;
    display: flex;
    flex-direction: row;
    width: 100px;
    justify-content: space-between;
  }

  .player-big {
    width: 70%;
    height: 70%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .player-big-video {
    width: 560px;
    height: 70%;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 0;
    border-radius: 10%;
    background-color: var(--colore-background-big);
    position: relative;
  }
  .player-big-album {
    height: 270px;
    width: 270px;
  }
  .player-big-title-volume {
    width: 90%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #player-big-song {
    color: white;
    font-size: 20px;
    width: 95%;
    height: 100%;
    font-size: 25px;
    padding: 0;
  }
  #player-big-song2 {
    font-weight: lighter;
    color: white;
    width: fit-content;
    height: 100%;
    font-size: 35px;
    margin: 0;
  }
  .player-big-control-volume {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    z-index: 5;
  }
  .player-big-mute-max {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .player-big-control-volume {
    padding: 20px 0;
  }

  /* Small player CSS */
  .player-small {
    display: grid;
  }

  /*Evo player CSS */
  .player-evo {
    width: 700px;
    height: 400px;
    background-color: whitesmoke;
    margin: 100px auto;
    display: grid;
    grid-template-columns: 52% 48%;
    grid-template-rows: 10% 30% 45% 15%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .player-evo-navbar {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    background-color: var(--color-icon-navbar-evo);
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid var(--color-icon-navbar-evo);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-top: -5px;
  }

  .player-evo-data-container {
    margin-right: 5px;
    background-color: whitesmoke;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-bottom: 2px solid gainsboro;
    padding-right: 4px;
  }
  .player-evo-songs-data {
    background-color: whitesmoke;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    display: flex;
    flex-direction: row;
    border-bottom: 3px solid gainsboro;
  }

  .plaver-evo-footer {
    background-color: whitesmoke;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
    display: flex;
    flex-direction: row;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .player-evo-play-pause {
    width: 25%;
    height: 100%;
    justify-content: flex-start;
    padding: 5px 5px;
    display: flex;
    flex-direction: row;
  }

  .player-evo-volume {
    max-height: 60px;
    display: flex;
    font-size: 9pt;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    place-items: flex-end;
    min-height: 0px;
    min-width: 5%;
    max-width: 100%;
    height: 60px !important;
    overflow-x: auto;
    overflow-y: hidden;
    background: var(--color-player-evo-volume);
    border-bottom-right-radius: 15px;
  }
  .player-evo-control-volume {
    position: relative;
    margin: 0;
    width: 80%;
    height: 100%;
  }
  .player-evo-img-fluid {
    display: none;
    width: 95%;
    height: 92%;
    object-fit: contain;
    border-right: 2px solid gainsboro;
    margin: 5px auto;
  }
  .player-evo-interval {
    width: 100%;
    height: auto;
    margin-left: 5px;
  }
  #player-evo-name-container {
    padding-left: 5px;
    margin-left: 0px;
  }
  .player-evo-speaker {
    width: 100%;
    height: auto;
    font-size: large;
    margin-left: 5px;
  }
}
@media only screen and (max-width: 700px) {
  .player-evo {
    width: 100vw;
  }
  .container-radiosa {
    width: 100vw;
  }
}

@media only screen and (max-width: 780px) {
  .container-radiosa {
    width: 100vw;
    margin: 0;
  }
}
